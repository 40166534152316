<template>
    <v-container fluid class="grey lighten-5 my-0 px-0  py-0">
        <q-resources-submenu></q-resources-submenu>
        <template>
            <v-row class="mx-5 q-sticky-buffer">
                <!-- help and wayfinding -->
                <!--
                <v-col cols="12" class="text-right">
                    <v-bottom-sheet v-model="sheet">
                        <template v-slot:activator="{ on }">
                            <v-btn icon color="pink" v-on="on">
                                <v-icon>fas fa-question-circle</v-icon>
                            </v-btn>
                        </template>
                        <v-sheet class="text-left px-7" height="auto">
                            <div class="py-3" style="max-width:900px; margin:0 auto;">
                                <p class="q-display-2">Resources</p>
                                <p>Resources is part of the Symmetry Inranet. This section will be editable and updatable by the Marketing team.</p>
                                <p>It will include a variety of helpful info and tools to support the Symmetry team.</p>
                                <v-btn class="mt-6" color="error" @click="sheet = !sheet">close</v-btn>
                            </div>
                        </v-sheet>
                    </v-bottom-sheet>
                </v-col>
            -->
                <!-- /help and wayfinding -->
                <v-col cols="12">
                    <QTabs class="q-text-left mx-5" background-color="q_resources_1" dark show-arrows>
                        <v-tab>
                            Owners Circle
                        </v-tab>
                        <v-tab>
                            Power Dialer
                        </v-tab>
                        <v-tab>
                            Virtual Assistant
                        </v-tab>
                        <v-tab>
                            Virtual Mentor
                        </v-tab>
                        <v-tab-item class="lighest_grey">
                            <page slug="owners-circle"></page>
                        </v-tab-item>
                        <v-tab-item class="lighest_grey">
                            <page slug="power-dialer"></page>
                        </v-tab-item>
                        <v-tab-item class="lighest_grey">
                            <page slug="virtual-assistant"></page>
                        </v-tab-item>
                        <v-tab-item class="lighest_grey">
                            <page slug="virtual-mentor"></page>
                        </v-tab-item>
                    </QTabs>
                </v-col>
            </v-row>
        </template>
        <!-- </v-col>
        </v-row> -->
    </v-container>
</template>
<script>
import Page from '@/CMS/views/index.vue'
import QResourcesSubmenu from '@/components/navigation/Submenus/QResourcesSubmenu.vue';
import QTabs from '../../components/navigation/QTabs.vue'

export default {
    data() {
        return {
            tab: null,
            sheet: false
        }
    },
    components: {
        QResourcesSubmenu,
        Page,
        QTabs
    }
}

</script>
